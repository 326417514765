.App {
  text-align: center;
  background-color: whitesmoke;
}

.clickable {
  cursor: default;
}

.coloured {
  background-color: rgb(201, 71, 201);
}

.coloured-font {
  color: rgb(201, 71, 201) !important;
  font-weight: bold;
}

.app-button {
  background-color:  rgb(201, 71, 201);
  color: whitesmoke;
  font-weight: bold;
  border: none;
  padding: 3px 6px;
  border-radius: 5px;
}

.absenden-btn {
  margin-top: 15px;
}

nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5vw;
  padding: 20px;
}

.nav-elem {
  color: whitesmoke;
  font-weight: bold;
  text-decoration: none;
}

.nav-elem:active {
  color: black;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 2vh 0 4vh;
}

/*signup & login*/
.login,
.signup {
  align-items: center;
}

.signup-form,
.login-form {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 15px 5px;
  margin-top: 30px;
  border: 2px solid rgb(201, 71, 201);
  border-radius: 5px;
}

.log-sign-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px;
}

.log-sign-section > label {
  flex: 1;
  font-weight: bold;
}

.log-sign-section > .form-input {
  flex: 2;
}

.log-sign-section > .app-button {
  margin-top: 15px;
}

/*home*/
.home {
  background-color: rgba(201, 71, 201, 0.397);
  align-items: center;
}

.logo-taube {
  width: 30vh;
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 5vh;
  transition: transform 2s ease-in-out;
}

.logo-taube:hover {
  transform: translateX(500%);
}


/*userliste*/
.userlist {
  list-style: none;
  padding-left: 0;
}

.userlist > li {
  margin: 10px;
}

/*stream*/
.post-textarea {
  border-radius: 5px;
  margin-bottom: 15px;
}

.stream-item {
  padding: 0.5vh 4vw 2vh;
  margin: 15px 10vw;
}

#post {
  width: 40vw;
  height: 7vh;
}

.post {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
}

.delete-post-btn {
  margin-left: 1%;
}

/*nachrichten*/
.threadlist {
  list-style: none;
  padding-left: 0;
}

.threadlist > li {
  margin-bottom: 10px;
}

.threadlink {
  color: black;
  text-decoration: none;
  margin: 20px 0;
}

/*neue nachricht*/
.nachricht-formular {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: 1fr 7fr 1fr;
  gap: 15px;
  margin-top: 2vh;
}

.nachricht-formular > label {
  font-weight: bold;
}

.empf-label {
  grid-column: 1;
  grid-row: 1;
  text-align: end;
  margin-right: 20px;
}

.empf-input {
  grid-column: 2;
  grid-row: 1;
}

.neue-nachricht-label {
  grid-column: 1;
  grid-row: 2;
  text-align: end;
  margin-right: 20px;
}

.nachricht-input {
  grid-column: 2;
  grid-row: 2;
}

.button-container {
  grid-column: 2;
  grid-row: 3;
}

/*thread*/
.konversation {
  display: flex;
  flex-direction: column;
  margin: 0 10vw;
  padding: 15px;
  border: 1px solid black;
  border-radius: 5px;
}

.alte-nachrichten {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nachricht {
  width: 60%;
  background-color: rgba(201, 71, 201, 0.397);
  padding: 5px 10px;
  margin: 10px 5px;
  border: 1px solid rgb(201, 71, 201);
  border-radius: 5px;
}

.mine {
  align-self: flex-end;
}

.absender-tag {
  font-weight: bold;
}

.datum-tag {
  font-size: small;
  opacity: 0.4;
  margin-right: 10px;
}

.weitere-nachricht-form {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: 5fr 1fr;
  gap: 15px;
  margin-top: 2vh;
}

.weitere-nachricht-label {
  grid-column: 1;
  grid-row: 1;
  text-align: end;
  margin-right: 20px; 
  font-weight: bold;
}

.weitere-nachricht-text {
  grid-column: 2;
  grid-row: 1;
}

.button-container-wn {
  grid-column: 2;
  grid-row: 2;
}